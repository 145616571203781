import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, { class: "air8-list" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (i) => {
        return (_openBlock(), _createBlock(_component_a_descriptions_item, {
          key: i.prop,
          label: i.label
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.dataFormat(i)), 1)
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }))
}